import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import Button from '@mui/material/Button';
import "antd/dist/antd.min.css";
import './index.scss';
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from "react-redux";
import { useFetchQuery } from '../../services/cart';
import HelmetComponent from '../../Components/HelmetComponent';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Tag } from "antd";
import Stepper from '@mui/joy/Stepper';
import Step from '@mui/joy/Step';
import StepIndicator from '@mui/joy/StepIndicator';
import StepButton from '@mui/joy/StepButton';
import Check from '@mui/icons-material/Check';
var moment = require('moment');

function PurchaseSuccess() {
  const { userId, isPurchase, userToken, languageCode, cartToken, countryCode } = useSelector((state) => state.auth);
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation()
  const { success } = useParams();
  const dispatch = useDispatch();
  const order_number = new URLSearchParams(location.search).get('order') || null
  const token = new URLSearchParams(location.search).get('token') || null

  const { refetch } = useFetchQuery({ userId: userId, cartToken: cartToken, countryCode: countryCode }, { count: 1 }, { refetchOnMountOrArgChange: true })

  useEffect(() => {
    if (token && order_number) {
      refetch()
    }
    try {
      if (!isPurchase) {
        history.push(`/`)
      } else {
        if (isPurchase !== token) {
          history.push(`/`)
        }
      }

    } catch (e) {
    }
  }, [isPurchase, cartToken, token])


  let fontFamilyForAll
  if (languageCode === 'ar') {
    fontFamilyForAll = 'GessTwoMedium, sans-serif'
  } else if (languageCode === 'urdu') {
    fontFamilyForAll = 'AlQalam Shakir'
  } else {
    fontFamilyForAll = 'Poppins, sans-serif'
  }
  const fontFamily = {
    fontFamily: fontFamilyForAll,
  }

  function getDeliverydays(data, created) {
    function getTimeline() {
      if (Number(data) === 1) {
        return 'Tomorrow'
      } else {
        return ''
      }
    }
    if (Number(data) > 0) {
      return <><strong>{getTimeline()}</strong> {moment(created).add(Number(data), 'days').format('dddd Do MMM YYYY')}</>
    } else {
      return moment(created).add(10, 'days').format('dddd Do MMM YYYY')
    }
  }

  const groupData = window.PurchaseItems?.[0]?.items?.reduce((acc, obj) => {
    const key = obj.estimated_delivery;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});

  function getitesmData(data) {
    return (
      <div className='op-section'>
        <div className='op-card' style={{ background: '#fff0', padding: '20px' }}>
        <div style={{ paddingBottom: '1rem', color: '#030303', fontWeight: 400 }}>{t('your_order')}</div>
          <div className='op-card-header' style={{ paddingTop: '20px', background: '#fff0' }}>
            <div style={{ fontSize: '12px' }}>
              <div>Order Number: &nbsp;{window.PurchaseItems?.[0]?.order_number}</div>
              <div>Total Items: &nbsp;{window.PurchaseItems?.[0]?.items?.length}</div>
            </div>
            <div style={{ fontSize: '12px' }}>
              <div>Order Status: &nbsp;{window.PurchaseItems?.[0]?.status ?
                <span style={{ color: 'green' }}>Placed</span> :
                <span style={{ color: 'tomato' }}>Placed</span>}</div>
              <div>{t('order_placed_on')} {moment(data?.createdAt).format("MMM Do YYYY")}</div>
            </div>
          </div>
          {/* {!window.PurchaseItems?.[0]?.status &&
            <Tag color='error' style={{ margin: '10px 0px' }}>Payment is pending</Tag>
          } */}
          {data.shipping_status === 'cancelled' &&
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
              <Tag color='error' style={{ minHeight: '30px', height: '30px', display: 'flex', alignItems: 'center', textTransform: 'capitalize', fontSize: '1rem' }}>
                {data.shipping_status?.replace('_', ' ')}
              </Tag>
            </div>
          }
          {groupData && Object?.entries(groupData).map(([group, groupItems]) => (
            <div
              key={group}
              style={{
                border: '1px solid #eee',
                borderRadius: '10px',
                padding: '15px',
                background: '#f0f4f8',
                marginBottom: '15px'
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', paddingBottom: '10px' }}>
                {groupItems?.[0].shipping_status?.split(',')?.includes('delivered') ?
                  <div style={{ padding: '10px 0px 10px 0px', display: 'flex', alignItems: 'center' }}>
                    <Tag style={{ height: '30px', minHeight: '30px', display: 'flex', alignItems: 'center' }}>
                      Delivered on:&nbsp;{moment(groupItems?.[0].delivered_on).format('Do MMM YYYY HH:mm')}
                    </Tag>
                  </div> :
                  <div style={{ fontSize: '14px', fontWeight: 500 }}>
                    Delivery by&nbsp;{getDeliverydays(group, data?.createdAt)}
                  </div>
                }
                <div style={{ fontSize: '14px', fontWeight: 500 }}>Items: {groupItems?.length}</div>
              </div>
              <Stepper sx={{ width: '100%', marginBottom: '10px', flexWrap: 'wrap' }}>
                {groupItems?.[0].shipping_status?.split(',')?.map((step, index) => (
                  <Step
                    key={step}
                    indicator={
                      <StepIndicator
                        style={{ fontSize: '10px', width: '10px', height: '10px', padding: '8px', lineHeight: '10px' }}
                        variant={groupItems?.[0].shipping_status?.split(',')?.includes(step) ? 'solid' : 'soft'}
                        color={groupItems?.[0].shipping_status?.split(',')?.includes(step) ? 'primary' : 'neutral'}
                      >
                        {groupItems?.[0].shipping_status?.split(',')?.includes(step) ? <Check style={{ fontSize: '12px' }} /> : index + 1}
                      </StepIndicator>
                    }
                    sx={{
                      '&::after': {
                        ...(groupItems?.[0].shipping_status?.split(',')?.includes(step) && { bgcolor: 'primary.solidBg' }),
                      },
                    }}
                  >
                    <StepButton style={{ fontSize: '10px', textTransform: 'capitalize' }}>
                      {step?.replace(/_/g, ' ')}
                    </StepButton>
                  </Step>
                ))}
              </Stepper>
              <div className='op-item-section'>
                {groupItems?.map((items, index) =>
                  <div key={index}>
                    <div className='op-item-card' style={{ background: '#fff', padding: '12px' }}>
                      <img className='op-item-img' src={items.image} />
                      <div className='op-item-title'>
                        <Link disabled to={``}>
                          <p style={{ margin: 0 }}>{items.title?.replace(/[^\w\s.,/:']/gi, ' ')}</p>
                        </Link>
                        <div className='op-item-content' style={{ padding: '10px 0px 10px 0px' }}>
                          <div style={{ display: 'flex', alignItems: 'center', fontSize: '12px', flexWrap: 'wrap' }}>
                            <Tag>Condition: {items.conditions}</Tag>
                            <Tag>Qty.: {items.quantity}</Tag>
                          </div>
                        </div>
                        {items.current_variant &&
                          <div style={{ padding: '10px 0px 10px 0px', display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                            {Object.entries(items.current_variant || []).map(([key, value], index) => (
                              <Tag style={{ fontSize: '10px' }} key={index}><strong>{key}:</strong> {value}</Tag>
                            ))}
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  }

  return (
    <>
      <HelmetComponent title={'Purchase success'} />
      <div className="Purchase-page-Success Account-page">
        <br /><br />
        <div className="container purchase-container">
          <div className="row purchase-section">

            <div className="col-md-6">
              <div className="row" style={{ justifyContent: 'center', width: '100%' }}>
                {isPurchase &&
                  <div className="col-md-12">
                    <div>
                      <div>
                        <div style={{ display: 'flex', alignItems: 'center', border: '1px solid #eee', borderRadius: '6px', padding: '20px' }}>
                          <div><CheckCircleIcon style={{ color: 'green', fontSize: '2rem' }} /></div>
                          <div style={{ margin: '0px 10px', fontSize: '1.2rem' }}>
                            Your order <strong>#{order_number}</strong> has been placed successfully. <br />Thank you
                          </div>
                        </div>
                        <br />
                        {window.PurchaseItems?.[0]?.bank_transfer &&
                          <div style={{ textAlign: 'left' }}>
                            <small style={{ color: 'red' }}>Important Note:</small>
                            <div style={{ lineHeight: 1.3 }}><p>To initiate procurement of your order please transfer the total amount of your order to the bank account below and send us an email help@thwifty.com with the proof of payment attached.</p></div>
                            <div>
                              <div><small>Thwifty Bank Account Details</small></div>
                              <div><small><strong>Bank Name:</strong> Mashreq Bank divJSC</small></div>
                              <div><small><strong>Account Name:</strong> Thwifty Go Buy Right General Trading LLC</small></div>
                              <div><small><strong>IBAN:</strong> E480330000019101436783</small></div>
                              <div><small><strong>Account Number:</strong> 019101436783</small></div>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>

            {window.PurchaseItems?.[0]?.items &&
              <div className="col-md-6">
                <div className="row" style={{ justifyContent: 'center', width: '100%' }}>
                  <div className="col-md-12">
                    <>
                      {getitesmData(window.PurchaseItems?.[0]?.items)}
                    </>
                  </div>
                </div>
              </div>
            }
          </div>
        </div >
        <br /><br />
      </div >
    </>
  );
}

export default PurchaseSuccess;
