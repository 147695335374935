import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import { Modal } from 'react-bootstrap'
import "antd/dist/antd.min.css";
import './index.scss';
import { useTranslation } from 'react-i18next'
import { useGetAllPaymentsQuery, useRemovePaymentsMutation, useDefaultPaymentsMutation } from '../../../services/payments';
import { useGetUserDataQuery, useGetShippingAddressesQuery } from '../../../services/login';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../../Purchase/Checkout";
import { message, Switch as Clickable, Tag } from "antd";
import { useSelector } from "react-redux";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Radio from '@mui/material/Radio';
import Button from '@mui/joy/Button';
import HelmetComponent from '../../../Components/HelmetComponent';
import CardsSVGs from '../../../Components/CardsSVGs';
import SideMenu from '../SideMenu';
import CircularProgress from '@mui/material/CircularProgress';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY);

function ShippingAddresses() {
  const { userId, userToken, userEmail, userName, isMember, isPaymentMethod, isCustomer, languageCode, countryCode } = useSelector((state) => state.auth);
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation()


  if (!userEmail && !userToken) {
    history.push('/')
  }

  const userData = useGetUserDataQuery({ userId: userId, userToken: userToken, countryCode: countryCode }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const customerNumber = userData?.data?.user?.customer


  const responseInfo = useGetAllPaymentsQuery({ userId: userId, userToken: userToken, countryCode: countryCode }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const paymentData = responseInfo?.data?.result?.data
  const customerData = responseInfo?.data?.customer

  const ShippingInfo = useGetShippingAddressesQuery({ userId: userId, userToken: userToken, countryCode: countryCode }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const ShippingData = ShippingInfo?.data?.shipping_addresses


  const [removePayment, removeResult] = useRemovePaymentsMutation()
  const [makeDefault, defaultResult] = useDefaultPaymentsMutation()


  const [paymentCompleted, setPaymentCompleted] = useState(false);


  const [getModal, setModal] = useState(false)
  function paymentModal() {
    setModal(true)
  }

  function closeModal() {
    setModal(false)
    setPaymentCompleted(false)
  }
  useEffect(() => {
    if (paymentCompleted) {
      setModal(true)
    }
  }, [paymentCompleted]);



  function getPaymentlength() {
    if (paymentData?.length === 5) {
      return true
    } else if (paymentData?.length < 5) {
      return true
    } else if (!paymentData) {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      // behavior: "smooth"
    });
  }, [])


  const successMessage = () => {
    return (
      <div className="success-msg">
        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-check2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
        </svg>
        <div className="title">New Payment Added Successfully</div>
        <br />
        <Button
          style={{ textTransform: 'capitalize' }}
          size="small"
          variant='outlined'
          onClick={(() => closeModal())}>
          {t('close')}
        </Button>
      </div>
    )
  }


  return (
    <>
      <HelmetComponent title={'Payment methods'} />
      <div className="Account-page tabel_cards">
        <Modal className="shipping-dialogue" show={getModal} onHide={closeModal} centered animation={false}>
          <Modal.Body>
            <IconButton
              style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '10px', zIndex: 111 }}
              onClick={closeModal}
            >
              <CloseIcon className="Header-close-modal-icon" />
            </IconButton>
            {
              paymentCompleted ?
                successMessage() :
                <React.Fragment>
                  <div className="col-md-12 order-md-1">
                    <Elements stripe={stripePromise}>
                      <CheckoutForm
                        customerNumber={customerNumber && customerNumber}
                        setPaymentCompleted={setPaymentCompleted}
                        getPaymentMethodForm={true}
                      />
                    </Elements>
                  </div>
                </React.Fragment>
            }
          </Modal.Body>
        </Modal>

        <div className="container">
          <div className='account-section'>
            <SideMenu />
            <div className='op-section' style={{ width: '100%' }}>
              <div className="row" style={{ justifyContent: 'center' }}>
                {!ShippingInfo?.isLoading &&
                  <div className="col-md-12">
                      <div className="ap-title">{t('Shipping Addresses')}</div>
                  </div>
                }

                {ShippingInfo?.isLoading &&
                  <div className='col-md-12' style={{ textAlign: 'center' }}>
                    <CircularProgress style={{ width: '2rem', height: '2rem', color: '#fe7f4a' }} />
                  </div>
                }

                {ShippingData?.map((item, i) =>
                  <div className="col-md-7" key={i}>
                    <div style={{ border: '1px solid #eee', borderRadius: '10px', background: '#fff', padding: '20px', marginBottom: '10px' }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div>
                          <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', fontSize: '14px' }}>
                            <div className="cart-page-text">
                              <div style={{ width: '100%' }}>
                                <div style={{ fontSize: '14px' }}>{item.name}, {item.address}, {item.street}, {item.city}, {item.country_code}</div>
                                <div style={{ fontSize: '14px' }}>{item.phone}</div>
                              </div>
                            </div>

                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {ShippingData?.length === 0 &&
                  <div className='col-md-12' style={{ textAlign: 'center' }}>
                    <div className="no-data-found"> No data found</div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ShippingAddresses;