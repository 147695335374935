import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Modal } from 'react-bootstrap'
import "antd/dist/antd.min.css";
import './index.scss';
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from "react-redux";
import { useGetUserDataQuery } from '../../services/login';
import { useTapCheckoutMutation, useTapCheckoutConfirmMutation } from '../../services/payments';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import IconButton from '@mui/material/IconButton';
import Host from '../../Components/Host';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import CloseIcon from '@mui/icons-material/Close';
import { addCheckoutMethod, addGuestShippment, addGuestPayment } from "../../Reducers/authReducer";
import { useFetchQuery } from '../../services/cart';
import Countries from '../../Components/Header/Countries';
import 'react-phone-number-input/style.css'
import Button from '@mui/joy/Button';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import Loader from '../../Components/Loader';
import HelmetComponent from '../../Components/HelmetComponent';
import PurchaseItems from '../../Components/PrchaseItems';
import CircularProgress from '@mui/material/CircularProgress';
import formatPrice from '../../Components/formatPrice';
import { TapCard, Currencies, Direction, Edges, Locale, Theme } from '@tap-payments/card-sdk'
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import Skeleton from '@material-ui/lab/Skeleton';
import GuestPay from '../../Components/PaybyApplePay/GuestPay';
import ApplePayCheckout from '../../Components/ApplePayCheckout';

import { loadStripe } from "@stripe/stripe-js";
import { Elements, useStripe } from "@stripe/react-stripe-js";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY);

function TapPurchaseGuest() {
  const { userId, userToken, userEmail, userName, languageCode, countryCode, cartToken, guestShippment, guestPayment, isPurchase } = useSelector((state) => state.auth);
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation()
  const dispatch = useDispatch();
  const findCountry = Countries?.find((l) => l.code === countryCode)
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [getSelectStatus, setSelectStatus] = React.useState(true);
  const orderId = new URLSearchParams(location.search).get('orderId') || null
  const confirmToken = new URLSearchParams(location.search).get('token') || null
  const tapId = new URLSearchParams(location.search).get('tap_id') || null
  const [loading, setLoading] = useState(false);
  const { data: userData } = useGetUserDataQuery({ userId: userId, userToken: userToken, countryCode: countryCode }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const couponCode = new URLSearchParams(location.search).get('coupon') || null
  const [getCoupon, setCoupon] = useState('')
  const { data: cartInfo, isLoading, isFetching, refetch } = useFetchQuery({ coupon: couponCode, userId: userId, cartToken: cartToken, countryCode: countryCode }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const cartData = cartInfo?.cart
  const thisToken = cartInfo?.token
  const couponData = cartInfo?.coupon
  const [getPhone, setPhone] = useState('')
  const [getPhoneError, setPhoneError] = useState(false)

  const [createcheckout, checkoutResult] = useTapCheckoutMutation()
  const [createcheckoutconfirm, checkoutonfrmresult] = useTapCheckoutConfirmMutation()

  useEffect(() => {
    try {
      if (checkoutonfrmresult?.data?.success) {
        dispatch(addCheckoutMethod({ isPurchase: cartToken }))
        dispatch(addGuestShippment({ guestShippment: '' }))
        dispatch(addGuestPayment({ guestPayment: '' }))
        refetch()
        let ItemsData = []
        checkoutonfrmresult?.data?.items?.map((item) => {
          ItemsData.push({
            item_id: item?.product_id,
            item_name: item?.title,
            price: item?.price,
            quantity: item?.quantity
          })
        })
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'purchase',
          ecommerce: {
            transaction_id: checkoutonfrmresult?.data?.order?.id,
            currency: checkoutonfrmresult?.data?.order?.currency,
            value: Number(checkoutonfrmresult?.data?.order?.value),
            shipping: Number(checkoutonfrmresult?.data?.order?.shipping),
            items: ItemsData
          }
        });
        let ItemsDataSuccess = []
        checkoutonfrmresult?.data?.items?.map((item) => {
          ItemsDataSuccess.push({
            product_id: item?.product_id,
            title: item?.title,
            conditions: item?.conditions,
            current_variant: item?.current_variant,
            estimated_delivery: item?.estimated_delivery,
            quantity: item?.quantity,
            image: item?.image
          })
        })
        window.PurchaseItems = [];
        window.PurchaseItems.push({
          order_number: checkoutonfrmresult?.data?.order_number,
          status: checkoutonfrmresult?.data?.status,
          items: ItemsDataSuccess
        });
        history.push(`/purchase/success?order=${checkoutonfrmresult?.data?.order_number}&token=${checkoutonfrmresult?.data?.token}`)
      } else {
        setLoading(false);
      }

    } catch (e) {
      setLoading(false);
    }

  }, [checkoutonfrmresult, checkoutonfrmresult?.data?.success])

  const [value, setValue] = React.useState(false);
  const TotalPrice = cartData?.reduce((accumulator, value) => {
    const productPrice = value.prices?.value * value.quantity;
    return accumulator + productPrice
  }, 0);

  if ((userId && userToken)) {
    history.push(`/purchase`)
  }

  const [getShippingModal, setShippingModal] = React.useState(false);
  function addShippingModal() {
    setShippingModal(true)
  }
  function closeShippingModal() {
    setShippingModal(false)
  }

  async function createshippingaddress(data) {
    if (isValidPhoneNumber(getPhone)) {
      dispatch(addGuestShippment({
        guestShippment: {
          name: data.name,
          email: data.email,
          address: data.address,
          street: data.street,
          city: data.city,
          country: findCountry?.label,
          country_code: findCountry?.code,
          zip_code: data.zip_code,
          phone: getPhone
        }
      }));
      setShippingModal(false)
    } else {
      setPhoneError(true)
    }
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      // behavior: "smooth"
    });
  }, [])

  function selectDefaultPayment(data) {
    setSelectStatus(true)
  }

  const [isValidInput, setValidInput] = useState(false);
  function buttonDisabled() {
    if (checkoutonfrmresult.isLoading) {
      return true
    } else if (checkoutResult?.isLoading) {
      return true
    } else if (!getSelectStatus) {
      return true
    } else if (loading) {
      return true
    } else if (!guestShippment?.name) {
      return true
    } else if (!isValidInput) {
      return true
    } else {
      return false
    }
  }

  const ppbutton = {
    height: '50px',
    background: buttonDisabled() ? '#f0f4f8' : Host?.bgColor,
    color: buttonDisabled() ? '#444' : Host?.buttonColor,
    width: '100%'
  }

  const [getTapCardError, setTapCardError] = useState('');
  const [getTestLoading, setTestLoading] = useState(false);
  const [isCardReady, setOnReady] = useState(false);

  useEffect(() => {
    if (checkoutResult?.isSuccess) {
      dispatch(addCheckoutMethod({ isPurchase: checkoutResult?.data?.purchase_token }))
      setTestLoading(true)
      const tokenUrl = checkoutResult?.data?.data?.transaction?.url
      window.location.href = tokenUrl;
      setTestLoading(false)
    }
    if (checkoutResult?.isError) {
      setTestLoading(false)
    }
  }, [checkoutResult, checkoutResult?.isSuccess, getTestLoading])







  const confirmCheckoutData = {
    shipping_address: guestShippment,
    language: languageCode,
    country: countryCode,
    amount: couponData ? Number(TotalPrice) - Number(couponData?.discount) : Number(TotalPrice),
    shipping: Number(userData?.contant?.standard_delivery),
    currency: cartData?.[0]?.prices?.currency,
    delivery_days: Number(userData?.contant?.delivery_days),
    name: guestShippment?.name,
    email: guestShippment?.email,
    products: cartData,
    cartToken: cartToken,
    domainName: Host?.domainName,
    domain: window.location.hostname,
    domainEmail: Host?.email,
    userEmail: guestShippment?.email,
    countryCode: countryCode,
    token: thisToken,
    coupon: couponData ? couponData : null,
    payby_checkout_confirm: 'payby_checkout_confirm',
    order_id: orderId,
    confirm_token: confirmToken,
    tapId: tapId,
    purchase_token: isPurchase
  }


  const checkoutAmount = couponData ? Number(TotalPrice) - Number(couponData?.discount) : Number(TotalPrice)
  const totalAmount = Number(checkoutAmount) + Number(userData?.contant?.standard_delivery)

  useEffect(() => {
    async function callAndConfirm() {
      if (tapId || orderId) {
        if (cartData, thisToken) {
          await createcheckoutconfirm(confirmCheckoutData)
        }
      }
    }
    callAndConfirm()
  }, [confirmToken, tapId, couponData, orderId, guestShippment])

  const phoneNumber = guestShippment?.phone ? guestShippment?.phone : '971509235977';
  const parsedNumber = parsePhoneNumberFromString(phoneNumber);
  const countryPhoneCode = parsedNumber?.countryCallingCode;
  const countryPhoneNumber = parsedNumber?.nationalNumber;


  const handleClick = () => {
    setTestLoading(true)
    if (window.CardSDK && typeof window.CardSDK.tokenize === 'function') {
      setTestLoading(true)
      window.CardSDK.tokenize();
      // setTestLoading(false)
    } else {
      console.error('CardSDK or CardSDK.tokenize() is not available.');
      setTestLoading(false)
    }
    // setTestLoading(false)
  };


  function getThisLoading(loading1, loading2, loading3, loading4, loading5) {
    if (loading1) {
      return true
    } else if (loading2) {
      return true
    } else if (loading3) {
      return true
    } else if (loading4) {
      return true
    } else if (loading5) {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    if (cartInfo) {
      window.cartData = cartInfo?.cart
      window.thisToken = cartInfo?.token
      window.couponData = cartInfo?.coupon
      window.guestShippment = guestShippment
      window.userData = userData
    } else {
      window.cartData = null
      window.thisToken = null
      window.couponData = null
      window.guestShippment = null
      window.userData = null
    }
  }, [cartInfo, thisToken, couponData, guestShippment, userData]);


  async function onsuccesssss(data) {
    try {
      const thisTotalPrice = window.cartData?.reduce((accumulator, value) => {
        const productPrice = value.prices?.value * value.quantity;
        return accumulator + productPrice
      }, 0);
      const thisPayload = {
        shipping_address: window.guestShippment,
        language: languageCode,
        country: countryCode,
        amount: window.couponData ? Number(thisTotalPrice) - Number(window.couponData?.discount) : Number(thisTotalPrice),
        shipping: Number(window.userData?.contant?.standard_delivery),
        currency: window.cartData?.[0]?.prices?.currency,
        delivery_days: Number(window.userData?.contant?.delivery_days),
        name: window.guestShippment?.name,
        email: window.guestShippment?.email,
        products: window.cartData,
        cartToken: cartToken,
        domainName: Host?.domainName,
        domain: window.location.hostname,
        domainEmail: Host?.email,
        userEmail: window.guestShippment?.email,
        countryCode: countryCode,
        token: window.thisToken,
        coupon: window.couponData ? window.couponData : null,
        tap_checkout: true,
        payment_type: 'tap',
        customer: window.userData?.user?.customer,
        user: false,
        countryPhoneCode: countryPhoneCode,
        countryPhoneNumber: countryPhoneNumber
      }
      const taData = { data: data };
      await createcheckout({ ...thisPayload, ...taData });
    } catch (e) {
      console.log('checkout-error', e)
    }
  }


  async function checkoutWithTabby() {
    try {
      const thisTotalPrice = window.cartData?.reduce((accumulator, value) => {
        const productPrice = value.prices?.value * value.quantity;
        return accumulator + productPrice
      }, 0);
      const thisPayload = {
        shipping_address: window.guestShippment,
        language: languageCode,
        country: countryCode,
        amount: window.couponData ? Number(thisTotalPrice) - Number(window.couponData?.discount) : Number(thisTotalPrice),
        shipping: Number(window.userData?.contant?.standard_delivery),
        currency: window.cartData?.[0]?.prices?.currency,
        delivery_days: Number(window.userData?.contant?.delivery_days),
        name: window.guestShippment?.name,
        email: window.guestShippment?.email,
        products: window.cartData,
        cartToken: cartToken,
        domainName: Host?.domainName,
        domain: window.location.hostname,
        domainEmail: Host?.email,
        userEmail: window.guestShippment?.email,
        countryCode: countryCode,
        token: window.thisToken,
        coupon: window.couponData ? window.couponData : null,
        tap_checkout: true,
        payment_type: 'tap_tabby',
        customer: window.userData?.user?.customer,
        user: false,
        countryPhoneCode: countryPhoneCode,
        countryPhoneNumber: countryPhoneNumber
      }
      const taData = { data: { id: 'src_tabby.installement' } };
      await createcheckout({ ...thisPayload, ...taData });
    } catch (e) {
      console.log('checkout-error', e)
    }
  }

  function formatPriceTabby(price, currency, shipping) {
    const numericPrice = Number(price) + Number(shipping);
    const tabbySplitPrice = Number(numericPrice) / 4
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency ? currency : 'AED',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })?.format(tabbySplitPrice);
  };

  const tapPublicKey = window.location.hostname === 'www.thwifty.ae' ? process.env.REACT_APP_TAP_PUBLIC_KEY_AE : process.env.REACT_APP_TAP_PUBLIC_KEY
  const tabbyCurrencies = ['SAR', 'AED', 'BHD', 'QAR', 'KWD']


  const [loadingAuth, setLoadingAuth] = useState(false);
  const [isErrorMessage, pErrorMessage] = useState('');

  return (
    <>
      {getThisLoading(checkoutonfrmresult?.isLoading, checkoutResult?.isLoading, getTestLoading, checkoutResult?.isSuccess, loadingAuth) &&
        <div style={{ position: 'absolute', right: 0, left: 0, top: 0, zIndex: 999, display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', background: '#ffffff7d' }}>
          <div style={{ textAlign: 'center' }}>
            <div style={{ marginBottom: '10px', color: '#000' }}>Processing Please Wait!</div>
            <CircularProgress style={{ width: '2rem', height: '2rem', color: '#fe7f4a' }} />
          </div>
        </div>
      }

      <HelmetComponent title={'Guest purchase'} />
      <div className="Purchase-page">
        <Modal className="shipping-dialogue" show={getShippingModal} onHide={closeShippingModal} centered animation={false}>
          <Modal.Body>
            <IconButton
              style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '10px', zIndex: 111 }}
              onClick={closeShippingModal}
            >
              <CloseIcon className="Header-close-modal-icon" />
            </IconButton>
            <div className="row">
              <div className="col-md-12">
                <h5 style={{
                  textAlign: 'center',
                  padding: '10px',
                  fontWeight: 400,
                  fontSize: '1rem'
                }}>Add new shipping address</h5>
              </div>
            </div>
            <form onSubmit={handleSubmit(createshippingaddress)}>
              <div className="row">
                <div className="col-md-5">
                  <div className="form-group">
                    <label style={{ fontSize: '14px' }}>{t('name')} </label>
                    <input
                      defaultValue={guestShippment?.name}
                      type="text"
                      className="form-control"
                      id="name"
                      aria-invalid={errors.name ? "true" : "false"}
                      {...register('name', { required: true })} />
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="form-group">
                    <label style={{ fontSize: '14px' }}>{t('email')} </label>
                    <input
                      defaultValue={guestShippment?.email}
                      type="text"
                      className="form-control"
                      id="email"
                      aria-invalid={errors.email ? "true" : "false"}
                      {...register('email', { required: true })} />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label style={{ fontSize: '14px' }}>{t('address_flat')} </label>
                    <input
                      defaultValue={guestShippment?.address}
                      type="text"
                      className="form-control"
                      id="address"
                      aria-invalid={errors.address ? "true" : "false"}
                      {...register('address', { required: true })} />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label style={{ fontSize: '14px' }}>{t('street_area')} </label>
                    <input
                      defaultValue={guestShippment?.name}
                      type="text"
                      className="form-control"
                      id="street"
                      aria-invalid={errors.street ? "true" : "false"}
                      {...register('street', { required: true })} />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label style={{ fontSize: '14px' }}>{t('city')} </label>
                    <input
                      defaultValue={guestShippment?.city}
                      type="text"
                      className="form-control"
                      id="city"
                      aria-invalid={errors.city ? "true" : "false"}
                      {...register('city', { required: true })} />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label style={{ fontSize: '14px' }}>{t('country')} </label>
                    <select className="form-control">
                      <option disabled selected>{findCountry?.label}</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label style={{ fontSize: '14px' }}>{t('phone')} </label>
                    <PhoneInput
                      international
                      countryCallingCodeEditable={false}
                      defaultCountry={countryCode}
                      value={getPhone}
                      onChange={setPhone}
                    />
                    {getPhoneError &&
                      <>
                        {getPhone && isValidPhoneNumber(getPhone) ? '' :
                          <div><small style={{ color: 'tomato' }}>{'Invalid phone number'}</small></div>
                        }
                      </>
                    }
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label style={{ fontSize: '14px' }}>{t('zip_code')} </label>
                    <input
                      defaultValue={guestShippment?.zip_code}
                      type="number"
                      className="form-control"
                      id="zip_code"
                      aria-invalid={errors.zip_code ? "true" : "false"}
                      {...register('zip_code', { required: false })} />
                  </div>
                </div>
                <div className="col-md-12 Header-signin-button">
                  <div className="form-group">
                    <Button
                      style={{ background: Host?.bgColor, color: Host?.buttonColor, width: '100%', height: '45px' }}
                      type="submit">
                      {t('submit')}
                    </Button>

                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>

        <div className="container purchase-container">
          <div className="row purchase-section">
            <div className="col-md-6 p0">
              <div className="purchase-form-card">
                <div className='pp-2-padding'>
                  <>
                    <div>
                      <div style={{ paddingBottom: '15px', color: '#030303', fontWeight: 400 }}>
                        {t('select_shipping_address')} <br />
                        {!guestShippment && !guestShippment?.name && <small style={{ color: 'tomato', fontSize: '12px' }}>Add shipping address</small>}
                      </div>
                      <div style={{ marginBottom: '20px', borderBottom: '1px solid #eee', width: '100%', height: '60px', display: 'flex', alignItems: 'center', padding: '0px', justifyContent: 'space-between' }}>
                        <Button
                          size='sm'
                          variant='outlined'
                          style={{ textTransform: 'capitalize', fontWeight: 400 }}
                          onClick={(() => addShippingModal())}>
                          <small>{guestShippment ? t('change') : t('add')}</small>
                        </Button>
                      </div>
                      {guestShippment && guestShippment?.name &&
                        <>
                          <div className="col-md-12" style={{ marginBottom: '15px', padding: '0px', position: 'relative' }}>
                            <div className="cart-page-card"
                              style={{ border: '1px solid #7ea6ee8c', cursor: 'pointer', padding: '10px', borderRadius: 8, display: 'flex', alignItems: 'center', position: 'relative', background: 'whitesmoke' }}
                            >
                              <RadioGroup value={value} style={{ paddingRight: '10px' }}>
                                <FormControlLabel
                                  style={{ margin: 0 }}
                                  value={true}
                                  control={<Radio checked={guestShippment} />} />
                              </RadioGroup>
                              <div className="cart-page-text">
                                <div style={{ width: '100%' }}>
                                  <div style={{ fontSize: '14px' }}>{guestShippment?.name}, {guestShippment?.address}, {guestShippment?.street}, {guestShippment?.city}, {guestShippment?.country_code}</div>
                                  <div style={{ fontSize: '14px' }}>{guestShippment?.email}, {guestShippment?.phone}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      }
                    </div>
                  </>
                  <br />
                  <div style={{ paddingBottom: '15px', color: '#030303', fontWeight: 400 }}>
                    {t('select_payment_method')}
                  </div>

                  {cartData?.length === 0 &&
                    <div
                      style={{ display: 'flex', alignItems: 'center', justifyContent: 'start', fontWeight: 400, fontSize: '14px' }}>
                      <span style={{ color: 'tomato' }}>your cart is empty</span>
                    </div>
                  }

                  {isLoading &&
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '20px', marginTop: '20px' }}>
                      <CircularProgress style={{ width: '2rem', height: '2rem', color: '#fe7f4a' }} />
                    </div>
                  }
                  {cartData?.length > 0 &&

                    <>
                      {tabbyCurrencies?.includes(userData?.contant?.currency) &&
                        <div
                          onClick={(() => guestShippment?.name && checkoutWithTabby())}
                          style={{ background: '#fcfcfc', padding: '0px 10px 0px 10px', border: '1px solid #dddddd', borderRadius: 8, fontSize: '14px', display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between', marginBottom: '10px', cursor: 'pointer' }}>
                          <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', }}>
                            <div style={{ lineHeight: 1.1 }}>
                              <div style={{ display: 'block', padding: '10px 0px 10px 0px' }}>
                                <div style={{ paddingBottom: '2px' }}><img src='/tabby-logo-1.png' style={{ width: '50px' }} /></div>
                                <small>Split in 4 payments of&nbsp;
                                  {formatPriceTabby(couponData ? Number(TotalPrice) - Number(couponData?.discount) : TotalPrice, cartData?.[0]?.prices?.currency, userData?.contant?.standard_delivery)}
                                  . No interest</small>
                              </div>
                            </div>
                          </div>
                          <div style={{ fontSize: '11px', fontWeight: '500', display: 'flex', alignItems: 'center' }}>
                            <span>Continue with Tabby</span>
                            <ArrowForwardIcon style={{ fontSize: '1rem', margin: '0px 5px' }} />
                          </div>
                        </div>
                      }

                      <div className="row">
                        <div className="col-md-12" style={{ padding: '0px' }}>
                          <Elements stripe={stripePromise}>
                            <ApplePayCheckout
                              setLoadingAuth={setLoadingAuth}
                              pErrorMessage={pErrorMessage}
                              cartInfo={cartInfo}
                              guestShippment={guestShippment}
                              isGuest={true}
                              userData={userData}
                              data={{
                                domainName: Host?.domainName,
                                domain: window.location.hostname || Host?.domain,
                                domainEmail: Host?.email
                              }} />
                          </Elements>
                        </div>
                      </div>

                      {/* {window.ApplePaySession &&
                        <>
                          {userData?.contant?.currency === 'AED' &&
                            <div style={{ padding: '10px 0px 10px 0px' }}>
                              <GuestPay guestShippment={guestShippment} purchaseconfrmresult={checkoutonfrmresult} />
                            </div>
                          }
                        </>
                      } */}

                      <div
                        onClick={(() => selectDefaultPayment())}
                        style={{ background: '#fcfcfc', padding: '10px 10px 10px 10px', border: '1px solid #dddddd', borderRadius: 8, fontSize: '14px', display: 'block', alignItems: 'center', justifyContent: 'space-between', marginBottom: '10px' }}>

                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <RadioGroup value={value} style={{ paddingRight: '10px' }}>
                            <FormControlLabel
                              style={{ margin: 0 }}
                              value={true}
                              control={<Radio style={{ padding: 0 }} checked={getSelectStatus} />} />
                          </RadioGroup>
                          <div style={{ display: 'flex', alignItems: 'center', }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <span style={{ display: 'flex' }}>
                                <svg width="17" height="17" fill="currentColor" className="bi bi-credit-card" viewBox="0 0 16 16">
                                  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v1h14V4a1 1 0 0 0-1-1zm13 4H1v5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1z" />
                                  <path d="M2 10a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1z" />
                                </svg>
                              </span>
                              <span style={{ margin: '0px 5px', display: 'flex', alignItems: 'center', fontSize: '1rem' }}>Card</span>
                            </div>
                          </div>
                        </div>


                        {getSelectStatus &&
                          <div className='checkout-t-section' style={{ marginTop: '10px' }}>
                            {!isCardReady &&
                              <div style={{ padding: '5px' }}>
                                <Skeleton variant="rect" height={14} width={'80%'} style={{ borderRadius: '4px', marginBottom: '5px' }} />
                                <Skeleton variant="rect" height={14} width={'80%'} style={{ borderRadius: '4px' }} />
                              </div>
                            }
                            <div style={{ padding: '0px' }}>
                              <TapCard
                                publicKey={tapPublicKey}
                                merchant={{
                                  id: '41624891'
                                }}
                                transaction={{
                                  amount: couponData ? Number(TotalPrice) - Number(couponData?.discount) + Number(userData?.contant?.standard_delivery) : TotalPrice + Number(userData?.contant?.standard_delivery),
                                  currency: cartData?.[0]?.prices?.currency === 'AED' ? Currencies.AED : cartData?.[0]?.prices?.currency === 'SAR' ? Currencies.SAR : cartData?.[0]?.prices?.currency === 'BHD' ? Currencies.BHD : cartData?.[0]?.prices?.currency === 'EGP' ? Currencies.EGP : cartData?.[0]?.prices?.currency === 'EUR' ? Currencies.EUR : cartData?.[0]?.prices?.currency === 'GBP' ? Currencies.GBP : cartData?.[0]?.prices?.currency === 'KWD' ? Currencies.KWD : cartData?.[0]?.prices?.currency === 'OMR' ? Currencies.OMR : cartData?.[0]?.prices?.currency === 'QAR' ? Currencies.QAR : 'USD'
                                }}
                                customer={{
                                  name: [
                                    {
                                      lang: Locale.EN,
                                      first: guestShippment?.name,
                                    }
                                  ],
                                  editable: true,
                                  contact: {
                                    email: guestShippment?.email,
                                    phone: {
                                      countryCode: countryPhoneCode,
                                      number: countryPhoneNumber
                                    }
                                  }
                                }}
                                acceptance={{
                                  supportedBrands: ['AMEX', 'VISA', 'MASTERCARD'],
                                  supportedCards: ['ALL']
                                }}
                                fields={{
                                  cardHolder: true
                                }}
                                addons={{
                                  displayPaymentBrands: true,
                                  loader: true,
                                  saveCard: userToken ? true : false
                                }}
                                interface={{
                                  locale: Locale.EN,
                                  theme: Theme.LIGHT,
                                  edges: Edges.CURVED,
                                  direction: Direction.LTR
                                }}
                                onValidInput={(data) => setValidInput(data)}
                                onReady={() => setOnReady(true)}
                                onError={(data) => setTapCardError(data)}
                                onSuccess={((data) => onsuccesssss(data))}
                              />
                            </div>
                          </div>
                        }
                      </div>
                    </>
                  }
                </div>
              </div>
            </div>

            <div className="col-md-5 pp-padding">
              <div className="row">
                <div className="col-md-12 pp-padding">
                  <div className='pp-2-padding'>

                    {isErrorMessage &&
                      <div className='alert alert-danger' style={{ textAlign: 'center' }}>
                        {isErrorMessage}
                      </div>
                    }

                    {getTapCardError &&
                      <div className='alert alert-danger a-m-style'>
                        something went wrong! try after sometime.
                      </div>
                    }
                    {checkoutonfrmresult?.error?.data?.error &&
                      <div className='alert alert-danger a-m-style'>
                        {checkoutonfrmresult?.error?.data?.error}
                      </div>
                    }
                    {checkoutResult?.error?.data?.error &&
                      <div className='alert alert-danger a-m-style'>
                        {checkoutResult?.error?.data?.error}
                      </div>
                    }
                    <h6 style={{ paddingBottom: '15px', color: '#030303', fontWeight: 400 }}>{t('your_order')}</h6>
                    {isLoading &&
                      <div style={{ textAlign: 'center', padding: '10px 0px 10px 0px' }}>
                        <CircularProgress style={{ width: '2rem', height: '2rem', color: '#fe7f4a' }} />
                      </div>
                    }

                    {cartData?.length === 0 &&
                      <Button
                        component={Link}
                        to={`/`}
                        variant='soft'
                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '20px', marginTop: '20px', fontWeight: 400, fontSize: '1rem' }}>
                        <span>Add Item to Cart</span>
                      </Button>
                    }

                    {cartData &&
                      <PurchaseItems cartData={cartData} userData={userData} checkoutResult={checkoutResult} />
                    }
                    {cartData?.length > 0 &&
                      <div className='ppbt pp-info-section'>
                        <div className='ppbt' style={{ padding: '15px' }}>
                          <div className='coupon-section' style={{ display: 'flex' }}>
                            <div style={{ width: '100%' }}>
                              <input
                                disabled={couponCode}
                                placeholder={couponCode ? couponCode : 'Coupon'}
                                className='form-control coupon-input'
                                style={{ height: '40px', borderRadius: 0 }}
                                onChange={(e) => setCoupon(e.target.value)}
                              />
                            </div>
                            <Button
                              variant='outlined'
                              disabled={isLoading || isFetching || !couponCode && !getCoupon}
                              style={{ height: '40px', borderRadius: 0, border: 'none' }}
                              className='cp-buttons'
                              onClick={(() =>
                                couponCode ?
                                  history.push(`${window.location.pathname}`) :
                                  history.push(`${window.location.pathname}?coupon=${getCoupon}`)
                              )}
                            >
                              {isLoading || isFetching ?
                                <Loader /> :
                                couponCode ? 'Remove' : 'Apply'
                              }
                            </Button>
                          </div>
                          {couponData &&
                            <>
                              {couponData?.success && <small style={{ color: 'green' }}> {couponData?.success}</small>}
                              {couponData?.error && <small style={{ color: 'tomato' }}> {couponData?.error}</small>}
                            </>
                          }
                          <br />
                          <div style={{ padding: '5px 0px 5px 0px' }}></div>
                          <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '10px' }}>
                            <div><small>{t('sub_total')}</small></div>
                            <div>
                              <small>{formatPrice(TotalPrice, cartData?.[0]?.prices?.currency, 0)}</small>
                            </div>
                          </div>
                          {couponData?.success &&
                            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '10px' }}>
                              <div><small>{t('discount')}</small></div>
                              <div>
                                <small>-{formatPrice(couponData?.discount, cartData?.[0]?.prices?.currency, 0)}</small>
                              </div>
                            </div>
                          }
                          <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '10px' }}>
                            <div><small>{t('delivery')} {t('fee')}</small></div>
                            <div>
                              <small>{userData?.contant?.standard_delivery > 0 ?
                                formatPrice(0, userData?.contant?.currency, userData?.contant?.standard_delivery) :
                                t('free')}</small>
                            </div>
                          </div>
                          <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '10px' }}>
                            <div><small><strong>{t('total')}</strong> {t('inclusive')} {`(of VAT)`}</small></div>
                            <div>
                              {formatPrice(couponData ? Number(TotalPrice) - Number(couponData?.discount) : TotalPrice, cartData?.[0]?.prices?.currency, userData?.contant?.standard_delivery)}
                            </div>
                          </div>
                        </div>
                        <div className='purchase-button-bottom'>
                          <Button
                            style={ppbutton}
                            disabled={(buttonDisabled())}
                            onClick={(() => handleClick())}
                          >
                            {getThisLoading(checkoutonfrmresult?.isLoading, checkoutResult?.isLoading, getTestLoading, checkoutResult?.isSuccess) ?
                              <div className="spinner-border spinner-border-sm text-dark" style={{ fontSize: '7px' }} role="status"></div>
                              :
                              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                <span style={{ display: 'flex', alignItems: 'center' }}>
                                  <span>{t('Proceed')}&nbsp; </span>
                                  <span><ArrowForwardIcon style={{ fontSize: '1.2rem' }} /></span>
                                </span>
                                {formatPrice(couponData ? Number(TotalPrice) - Number(couponData?.discount) : TotalPrice, cartData?.[0]?.prices?.currency, userData?.contant?.standard_delivery)}
                              </div>
                            }
                          </Button>
                        </div>
                      </div>
                    }
                    <br />
                    <div><small style={{ fontSize: '12px' }}><strong>Security and Privacy</strong></small></div>
                    <div style={{ lineHeight: 1 }}><small style={{ fontSize: '12px', lineHeight: 1 }}>Our checkout is safe and secure. We do not store any payment card information on our systems</small></div>
                    <br />
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Link style={{ fontSize: '12px', margin: '0 5px' }} to="/privacy-policy">{t('privacy_policy')}</Link>
                      <Link style={{ fontSize: '12px', margin: '0 5px' }} to="/terms-and-conditions">{t('terms_and_conditions')}</Link>
                    </div>
                    <div style={{ textAlign: 'center' }}><small style={{ fontSize: '12px' }}>© 2024 {Host?.domainName}. All Rights Reserved</small></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
      </div>
    </>
  );
}

export default TapPurchaseGuest;
